<template>
    <div id="uploadProductInstallationService" class="container-fluid m-0 p-0">
        <div class="row m-0 p-3 justify-content-center">
            <div class="col-12 col-lg-12 m-0 p-0 shadow   bg-white rounded">
                <div class="row m-0 p-4">
                    <div class="col-12 mx-0 mb-3 mb-xl-0">
                        <p class="font-1-2s font-600">Upload Product Installation Service</p>
                    </div>
                </div>
                <div class="row m-0 p-2">
                    <div class="col-12 ">
                        <b-tabs content-class="mt-2 m-0 p-0">
                            <b-tab title="List Product" active>
                                <TableListProduct :key="tableKey" />
                            </b-tab>
                            <b-tab title="Upload Product">
                                <UploadProductInstall @uploadSuccess="reloadTable" />
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TableListProduct from '../../../components/MarketPlace/UploadSkuInstall/TableListProduct.vue'
import UploadProductInstall from '../../../components/MarketPlace/UploadSkuInstall/UploadProductInstall.vue'

export default {
    name: "UploadSkuInstall",
    components: {
        TableListProduct,
        UploadProductInstall
    },
    data() {
        return {
            tableKey: 0
        }
    },
    methods: {
        reloadTable() {
            // Incrementing the tableKey to force re-rendering of the table component
            this.tableKey++;
        }
    },
}
</script>
